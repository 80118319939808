const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/category-list/';
const brandSelectionPageUrl = '/brands/';

function getLocalPreselection(pageType, defaults) {
  const isCategoryPage = pageType === 'category';
  const isBrandPage = pageType === 'brand';
  if (isCategoryPage || isBrandPage) {
    // breadcrumbs selector differs from default
    const breadcrumbs = [...window.document.querySelectorAll('.breadcrumb .breadcrumb-item')];
    const isMetaFromZero = breadcrumbs.some(
      (el) => el.querySelector('meta')?.getAttribute('content') === '0',
    );

    const parsedBreadcrumbs = breadcrumbs
      .map((elem) => {
        const text = (elem.textContent || elem.innerText).trim();
        const metaAttr = elem.querySelector('meta')?.getAttribute('content');
        const ignore =
          text.toLowerCase() === 'home' || (isMetaFromZero ? metaAttr === '0' : metaAttr === '1');
        return !ignore && text;
      })
      .filter(Boolean);

    const termFromBreadcrumbs = parsedBreadcrumbs.join('>');
    if (termFromBreadcrumbs) {
      return [
        {
          field: isCategoryPage ? 'category' : 'brand_name',
          term: termFromBreadcrumbs,
          treeLevel: isCategoryPage ? parsedBreadcrumbs.length - 1 : null,
        },
      ];
    }
  }

  return defaults.getter(pageType);
}

export default {
  platform: 'bigcommerce',
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  page: {
    // when the "default" theme layout is applied, there will always be a "body.page-type-default" selector
    // this happens because the body class is defined like this: `page-type-{{theme_settings.theme_style}`
    getPageType: (defaults) =>
      window.document.querySelector('body.page--brand')
        ? 'brand'
        : window.document.querySelector('body.page--category')
          ? 'category'
          : window.document.querySelector('body.page--product')
            ? 'product'
            : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    categorySelectionPageRedirect: true,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { lastChildOf: '.page-content .products-category' },
      template: 'MainContent',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: { insertAfter: '.page--brand main > .container .page-content .page-heading' },
      template: 'MainContent',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: { firstChildOf: 'body:not(.page--product) main > .container .page-sidebar' },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchBox',
      location: { replace: 'header .header-search', class: 'header-search' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.header-mobilebottom .wb-searchpro',
        class: 'wb-searchpro',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_categories',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        replace: 'div[data-content-region=header_bottom--global]',
        class: 'cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 700,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        firstChildOf: 'header .header-blockcart .navUser .navUser-section',
        wrapper: 'li',
        class: 'navUser-item navUser-item--cart cm_garage__desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'GarageMobile',
      type: 'Garage',
      location: {
        firstChildOf: '.header-mobiletop .col-3.cart-container',
        class: 'cart-button cart-button--primary cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertBefore: 'body.page--product .productView-details.product-options',
        class: 'productView-details product-data',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tabs .tab:first-child',
        class: 'tab cm_fitment-table__tab',
        wrapper: 'li',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.productView-description .tabs-contents',
        class: 'tab-content',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
